import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginForm, LoginFormValue } from '@stur/components/common/login-form';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { AuthActions } from '@stur/store/auth/auth-reducer';

export const LogInPage: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    ActionStatusSelectors.isActionPending([AuthActions.logIn, AuthActions.completeLogin])
  );

  const handleSubmit = (values: LoginFormValue): void => {
    const { email, password } = values;
    dispatch(AuthActions.logIn({ provider: 'email', credentials: { email, password } }));
  };

  const handleFacebookAuth = (): void => {
    dispatch(AuthActions.logIn({ provider: 'facebook' }));
  };

  const handleGoogleAuth = (): void => {
    dispatch(AuthActions.logIn({ provider: 'google' }));
  };

  return (
    <>
      <PageTitle header="Log In" />
      <PageContent>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-6 large-4">
              <LoginForm
                isLoading={isLoading}
                onSubmit={handleSubmit}
                onFacebookAuth={handleFacebookAuth}
                onGoogleAuth={handleGoogleAuth}
              />
            </div>
            <div className="cell medium-6 large-8">
              <img src="https://picsum.photos/800/800" alt="About Stur" className="img-fluid" />
            </div>
          </div>
        </div>
      </PageContent>
    </>
  );
};
